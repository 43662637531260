<template>
  <div
    class="bg-white border-b border-b-gray-300 hidden lg:block sticky top-0 z-[9000]"
  >
    <div class="layout-grid">
      <div class="py-10 flex gap-24 col-start-1 col-end-[-1]">
        <VuepalLocalTasks class="mr-auto" />
        <button class="link" @click="showGrid = !showGrid">Grid</button>
        <button class="link" @click="toggleTextsDebug">
          {{ isTextsDebug ? 'Texte anzeigen' : 'Keys anzeigen' }}
        </button>

        <button class="link" @click="toggleDebug">
          {{ isDebug ? 'Debug verstecken' : 'Debug anzeigen' }}
        </button>

        <a :href="prodUrl" class="link" target="_blank"> Auf PROD anzeigen </a>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <div v-if="showGrid" class="bk-grid-overlay">
      <div class="grid-debug">
        <div class="layout-grid">
          <div v-for="n in 14" :key="n">
            {{ n }}
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onKeyStroke, useLocalStorage } from '@vueuse/core'

const showGrid = useLocalStorage('show_grid', false)

const route = useRoute()

const { toggleDebug: toggleTextsDebug, isDebug: isTextsDebug } = useEasyTexts()

const { isDebug, toggleDebug } = useDebug()

const prodUrl = computed(() => {
  return 'https://www.shkb.ch' + route.path
})

onKeyStroke('d', function (e) {
  if (!e.ctrlKey) {
    return
  }
  e.preventDefault()
  toggleDebug()
})
</script>

<style lang="postcss">
.bk .bk-edit-indicator {
  z-index: 9999999999999 !important;
}
</style>
